@font-face {
  font-family: 'IBM Plex Mono';
  src: url(/static/media/IBMPlexMono-Regular.c7832ed5.woff2) format('woff2'),
    url(/static/media/IBMPlexMono-Regular.3bbb37eb.woff) format('woff');
}
@font-face {
  font-weight: 700;
  font-family: 'IBM Plex Mono';
  src: url(/static/media/IBMPlexMono-Bold.0fe304ab.woff2) format('woff2'),
    url(/static/media/IBMPlexMono-Bold.e497c7d8.woff) format('woff');
}
@font-face {
  font-style: italic;
  font-family: 'IBM Plex Mono';
  src: url(/static/media/IBMPlexMono-Italic.e013d7fb.woff2) format('woff2'),
    url(/static/media/IBMPlexMono-Italic.cb143f14.woff) format('woff');
}
@font-face {
  font-weight: 700;
  font-style: italic;
  font-family: 'IBM Plex Mono';
  src: url(/static/media/IBMPlexMono-BoldItalic.4289aa97.woff2) format('woff2'),
    url(/static/media/IBMPlexMono-BoldItalic.0cc0d044.woff) format('woff');
}

html {
  /* overflow: hidden; */
  -webkit-font-feature-settings: "zero";
          font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
  font-family: sans-serif;
  font-size: 14px;
  font-family: 'IBM Plex Mono';
  line-height: 1.5;
  background: #444;
  color: #eee;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #eee;
}

button:focus {
  outline: none;
  background: yellow;
}

/* Uses javascript in index.html. from https://stackoverflow.com/a/51093815/8691291 */
.using-mouse button:focus {
  background: transparent;
}

button:active,
.using-mouse button:active,
.active {
  color: #111;
  background: #eee;
  text-decoration: none;
}

a {
  color: inherit;
}

p {
  margin: 0;
}
/*  */
.rainbow-animate {
  -webkit-animation: rainbow 10s linear infinite;
          animation: rainbow 10s linear infinite;
}

.rainbow-animate {
  -webkit-animation: rainbow 10s linear infinite;
          animation: rainbow 10s linear infinite;
}

.rainbow-animate-shadow {
  -webkit-animation: rainbow-shadow 10s linear infinite;
          animation: rainbow-shadow 10s linear infinite;
}

.gray-bg {
  background: #444;
}

@-webkit-keyframes rainbow {
  0% {
    background: #ffa3a3;
  }
  10% {
    background: #ffaa29;
  }
  20% {
    background: #a2ca00;
  }
  30% {
    background: #2bd900;
  }
  40% {
    background: #00d857;
  }
  50% {
    background: #00d1d1;
  }
  60% {
    background: #92beff;
  }
  70% {
    background: #c1b1ff;
  }
  80% {
    background: #ec9fff;
  }
  90% {
    background: #ff9cd7;
  }
  100% {
    background: #ffa3a3;
  }
}

@keyframes rainbow {
  0% {
    background: #ffa3a3;
  }
  10% {
    background: #ffaa29;
  }
  20% {
    background: #a2ca00;
  }
  30% {
    background: #2bd900;
  }
  40% {
    background: #00d857;
  }
  50% {
    background: #00d1d1;
  }
  60% {
    background: #92beff;
  }
  70% {
    background: #c1b1ff;
  }
  80% {
    background: #ec9fff;
  }
  90% {
    background: #ff9cd7;
  }
  100% {
    background: #ffa3a3;
  }
}

@-webkit-keyframes rainbow-shadow {
  0% {
    background: #ffa3a3;
    box-shadow: -0.25em 0 0 #ffa3a3, 0.25em 0 0 #ffa3a3;
  }
  10% {
    background: #ffaa29;
    box-shadow: -0.25em 0 0 #ffaa29, 0.25em 0 0 #ffaa29;
  }
  20% {
    background: #a2ca00;
    box-shadow: -0.25em 0 0 #a2ca00, 0.25em 0 0 #a2ca00;
  }
  30% {
    background: #2bd900;
    box-shadow: -0.25em 0 0 #2bd900, 0.25em 0 0 #2bd900;
  }
  40% {
    background: #00d857;
    box-shadow: -0.25em 0 0 #00d857, 0.25em 0 0 #00d857;
  }
  50% {
    background: #00d1d1;
    box-shadow: -0.25em 0 0 #00d1d1, 0.25em 0 0 #00d1d1;
  }
  60% {
    background: #92beff;
    box-shadow: -0.25em 0 0 #92beff, 0.25em 0 0 #92beff;
  }
  70% {
    background: #c1b1ff;
    box-shadow: -0.25em 0 0 #c1b1ff, 0.25em 0 0 #c1b1ff;
  }
  80% {
    background: #ec9fff;
    box-shadow: -0.25em 0 0 #ec9fff, 0.25em 0 0 #ec9fff;
  }
  90% {
    background: #ff9cd7;
    box-shadow: -0.25em 0 0 #ff9cd7, 0.25em 0 0 #ff9cd7;
  }
  100% {
    background: #ffa3a3;
    box-shadow: -0.25em 0 0 #ffa3a3, 0.25em 0 0 #ffa3a3;
  }
}

@keyframes rainbow-shadow {
  0% {
    background: #ffa3a3;
    box-shadow: -0.25em 0 0 #ffa3a3, 0.25em 0 0 #ffa3a3;
  }
  10% {
    background: #ffaa29;
    box-shadow: -0.25em 0 0 #ffaa29, 0.25em 0 0 #ffaa29;
  }
  20% {
    background: #a2ca00;
    box-shadow: -0.25em 0 0 #a2ca00, 0.25em 0 0 #a2ca00;
  }
  30% {
    background: #2bd900;
    box-shadow: -0.25em 0 0 #2bd900, 0.25em 0 0 #2bd900;
  }
  40% {
    background: #00d857;
    box-shadow: -0.25em 0 0 #00d857, 0.25em 0 0 #00d857;
  }
  50% {
    background: #00d1d1;
    box-shadow: -0.25em 0 0 #00d1d1, 0.25em 0 0 #00d1d1;
  }
  60% {
    background: #92beff;
    box-shadow: -0.25em 0 0 #92beff, 0.25em 0 0 #92beff;
  }
  70% {
    background: #c1b1ff;
    box-shadow: -0.25em 0 0 #c1b1ff, 0.25em 0 0 #c1b1ff;
  }
  80% {
    background: #ec9fff;
    box-shadow: -0.25em 0 0 #ec9fff, 0.25em 0 0 #ec9fff;
  }
  90% {
    background: #ff9cd7;
    box-shadow: -0.25em 0 0 #ff9cd7, 0.25em 0 0 #ff9cd7;
  }
  100% {
    background: #ffa3a3;
    box-shadow: -0.25em 0 0 #ffa3a3, 0.25em 0 0 #ffa3a3;
  }
}


